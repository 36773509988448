import { useState, useEffect } from "react";
import { Row, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useUser, SignOutButton } from "@clerk/clerk-react";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import {
  HeaderSection,
  LogoContainer,
  LogoText,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Outline,
  Span,
  HeaderControls,
  UserSection
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
  const [headerVisible, setHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const history = useHistory();
  const { isSignedIn, user } = useUser();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setHeaderVisible(currentScrollY < lastScrollY || currentScrollY < 50);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id);
      if (window.location.pathname !== '/') {
        history.push('/');
        setTimeout(() => {
          element?.scrollIntoView({ behavior: "smooth" });
        }, 100);
      } else {
        element?.scrollIntoView({ behavior: "smooth" });
      }
      setVisibility(false);
    };

    return (
      <HeaderControls>
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
          <Span>{t("Mission")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          <Link to="/search" onClick={() => setVisibility(false)}>
            <button className="header-button">{t("Find Domain")}</button>
          </Link>
        </CustomNavLinkSmall>
        {!isSignedIn ? (
          <>
            <CustomNavLinkSmall>
              <Link to="/auth" onClick={() => setVisibility(false)}>
                <Span>{t("Sign In")}</Span>
              </Link>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall>
              <Link to="/sign-up" onClick={() => setVisibility(false)}>
                <button className="header-button">{t("Sign Up")}</button>
              </Link>
            </CustomNavLinkSmall>
          </>
        ) : (
          <UserSection>
            <Span>{t("Welcome")}, {user?.primaryEmailAddress?.emailAddress}</Span>
            <CustomNavLinkSmall>
              <SignOutButton>
                <Span>{t("Sign Out")}</Span>
              </SignOutButton>
            </CustomNavLinkSmall>
          </UserSection>
        )}
      </HeaderControls>
    );
  };

  return (
    <HeaderSection visible={headerVisible}>
      <Container>
        <Row justify="space-between" align="middle" style={{ padding: '1rem 0' }}>
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" width="40px" height="40px" />
            <LogoText>AI Domain Finder</LogoText>
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={() => setVisibility(!visible)}>
            <Outline />
          </Burger>
        </Row>
        <Drawer 
          closable={false} 
          open={visible} 
          onClose={() => setVisibility(false)}
          width={280}
          placement="right"
          bodyStyle={{ padding: 0 }}
        >
          <div style={{ padding: '2rem 1.5rem' }}>
            <Row justify="space-between" align="middle" style={{ marginBottom: '2rem' }}>
              <Menu>Menu</Menu>
              <Outline onClick={() => setVisibility(false)} />
            </Row>
            <MenuItem />
          </div>
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);