interface RouteItem {
  path: string;
  component: string;
  exact?: boolean;
  protected?: boolean;
}

const routes: RouteItem[] = [
  {
    path: "/",
    component: "Home",
    exact: true,
  },
  {
    path: "/search",
    component: "Product",
    exact: true,
    protected: true
  },
  {
    path: "/auth",
    component: "Auth",
  },
  {
    path: "/sign-up",
    component: "SignUp",
  },
  {
    path: "/terms",
    component: "Terms",
    exact: true,
    protected: false
  },
  {
    path: "/privacy",
    component: "Privacy",
    exact: true,
    protected: false
  },
  {
    path: "/refund",
    component: "Refund",
    exact: true,
    protected: false
  }
];

export default routes;