import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterSection = styled.footer`
  background: #f8f9fa;
  padding: 2rem 0;
  border-top: 1px solid #eee;
`;

export const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 1.5rem;
    text-align: center;
  }
`;

export const FooterBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding-top: 2rem;
`;

export const ContactText = styled.div`
  font-size: 1rem;
  color: #4f5665;

  a {
    color: #2d3436;
    margin-left: 0.5rem;
    text-decoration: none;
    border-bottom: 1px solid #2d3436;
    transition: all 0.3s ease;

    &:hover {
      color: #0984e3;
      border-bottom-color: #0984e3;
    }
  }
`;

export const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  span {
    color: #4f5665;
    font-size: 1rem;
  }
`;

export const LanguageSwitch = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

export const SocialIcon = styled.a`
  color: #4f5665;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

export const Copyright = styled.div`
  color: #4f5665;
  font-size: 0.9rem;
`;

export const FooterLink = styled(Link)`
  color: #18216d;
  margin: 0 1rem;
  text-decoration: none;
  font-size: 14px;
  
  &:hover {
    color: rgb(255, 130, 92);
  }
`;