import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  path: string | string[];
  exact?: boolean;
}

// Temporarily disable auth check for review
const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Component {...props} />
      )}
    />
  );
};

/* Keep the original code commented out for later restoration
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useUser } from "@clerk/clerk-react";

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const { isSignedIn, isLoaded } = useUser();

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isSignedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};
*/

export default PrivateRoute;