import { ClerkProvider as BaseClerkProvider } from '@clerk/clerk-react';

interface ClerkProviderProps {
  children: React.ReactNode;
}

const ClerkProvider = ({ children }: ClerkProviderProps) => {
  const publishableKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
  
  if (!publishableKey) {
    console.warn('Missing Clerk Publishable Key');
    return <>{children}</>;
  }

  return (
    <BaseClerkProvider publishableKey={publishableKey}>
      {children}
    </BaseClerkProvider>
  );
};

export default ClerkProvider;