import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

export const HeaderSection = styled.header<{ visible: boolean }>`
  position: fixed;
  width: 100%;
  top: 0;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  transform: translateY(${props => props.visible ? '0' : '-100%'});
  z-index: 1000;
  

`;

export const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
`;

export const LogoText = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  color: #2e186a;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const HeaderControls = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;

    .header-button {
      width: 100%;
    }
  }
`;

export const NotHidden = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Menu = styled.h5`
  font-size: 1.1rem;
  font-weight: 600;
  text-align: left;
  margin: 0;
`;

export const CustomNavLinkSmall = styled.div`
  font-size: 1rem;
  color: #18216d;
  transition: color 0.2s ease-in;
  margin: 0.5rem 0;
  position: relative;

  &:not(:has(.header-button))::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    width: 0;
    height: 2px;
    background: linear-gradient(90deg, #2e186a 0%, #6441A5 100%);
    transition: all 0.3s ease;
    border-radius: 2px;
    transform: translateX(-50%);
  }

  &:not(:has(.header-button)):hover::after {
    width: 80%;
  }

  .header-button {
    background: linear-gradient(90deg, #2e186a 0%, #6441A5 100%);
    color: white;
    border: none;
    padding: 8px 24px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    position: relative;
    z-index: 1;

    &:hover {
      transform: translateY(-2px) scale(1.02);
      box-shadow: 0 4px 15px rgba(46, 24, 106, 0.3);
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 12px 24px;
    }
  }
`;

export const Label = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

interface OutlineProps {
  padding?: boolean;
}

export const Outline = styled(MenuOutlined)<OutlineProps>`
  font-size: 22px;
  padding-right: ${props => (props.padding ? "10px" : "")};
`;

export const Burger = styled.div`
  @media screen and (min-width: 769px) {
    display: none;
  }
  
  padding: 1rem;
  cursor: pointer;
`;

export const UserSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 0.5rem;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;

    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }
`;

export const Span = styled.span`
  cursor: pointer;
  transition: all 0.3s ease;
  color: #18216d;
  text-decoration: none;
  
  &:hover {
    color: rgb(255, 130, 92);
  }
`;