import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ClerkProvider from "../common/ClerkProvider";
import PrivateRoute from "../components/PrivateRoute";
import routes from "./config";
import { Styles } from "../styles/styles";

const Router = () => {
  return (
    <ClerkProvider>
      <Suspense fallback={null}>
        <Styles />
        <Header />
        <Switch>
          {routes.map((routeItem) => {
            return routeItem.protected ? (
              <PrivateRoute
                key={routeItem.component}
                path={routeItem.path}
                exact={routeItem.exact}
                component={lazy(() => import(`../pages/${routeItem.component}`))}
              />
            ) : (
              <Route
                key={routeItem.component}
                path={routeItem.path}
                exact={routeItem.exact}
                component={lazy(() => import(`../pages/${routeItem.component}`))}
              />
            );
          })}
        </Switch>
        <Footer />
      </Suspense>
    </ClerkProvider>
  );
};

export default Router;
