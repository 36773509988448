import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import i18n from "i18next";
import {
  FooterSection,
  FooterTop,
  FooterBottom,
  LanguageContainer,
  ContactText,
  SocialIcon,
  Copyright,
  LanguageSwitch,
  FooterLink,
} from "./styles";

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <FooterSection>
      <Container>
        <FooterTop>
          <ContactText>
            {t("Have questions?")} 
            <a href="mailto:xcelled.help@gmail.com">xcelled.help@gmail.com</a>
          </ContactText>
          <LanguageContainer>
            <span>{t("Language")}</span>
            <LanguageSwitch onClick={() => handleChange("en")}>
              <SvgIcon
                src="united-states.svg"
                aria-label="English"
                width="24px"
                height="24px"
              />
            </LanguageSwitch>
            <LanguageSwitch onClick={() => handleChange("es")}>
              <SvgIcon
                src="spain.svg"
                aria-label="Español"
                width="24px"
                height="24px"
              />
            </LanguageSwitch>
          </LanguageContainer>
        </FooterTop>
        <FooterBottom>
          <div>
            <FooterLink to="/terms">{t("Terms of Service")}</FooterLink>
            <FooterLink to="/privacy">{t("Privacy Notice")}</FooterLink>
            <FooterLink to="/refund">{t("Refund Policy")}</FooterLink>
          </div>
          <SocialIcon href="https://twitter.com/xcelledlabs">
            <SvgIcon src="twitter.svg" width="20px" height="20px" />
          </SocialIcon>
          <Copyright>© 2024 AI Domain Finder</Copyright>
        </FooterBottom>
      </Container>
    </FooterSection>
  );
};

export default withTranslation()(Footer);